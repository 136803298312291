import React, { useState, useEffect } from 'react';
import styled, { css, keyframes } from 'styled-components';

const slideInMobile = keyframes`
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
`;

const slideOutMobile = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
`;

const slideInDesktop = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;

const slideOutDesktop = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
`;

const SnackbarWrapper = styled.div`
  position: fixed;
  z-index: 100000;
  padding: 16px;
  border-radius: 8px;
  text-align: center;
  font-size: 16px;
  color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: ${({ type }) => (type === 'positive' ? '#4CAF50' : type === 'neutral' ? '#FFA500' : '#F44336')};

  ${(props) =>
    props.isMobile
      ? css`
          top: 0;
          left: 0;
          right: 0;
          margin: 16px auto;
          width: calc(100% - 32px);
          animation: ${props.isVisible ? slideInMobile : slideOutMobile} 0.5s forwards;
        `
      : css`
          top: 20px;
          right: 20px;
          width: 30%;
          animation: ${props.isVisible ? slideInDesktop : slideOutDesktop} 0.5s forwards;
        `}
`;

const CloseButton = styled.button`
  display: none;
  position: absolute;
  top: 8px;
  right: 8px;
  background: none;
  border: none;
  color: white;
  font-size: 18px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const Snackbar = ({ message, type = 'positive', duration = 5000, onClose }) => {
  const [isVisible, setIsVisible] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);

    const timer = setTimeout(() => {
      setIsVisible(false);
      onClose && onClose();
    }, duration);

    return () => {
      window.removeEventListener('resize', handleResize);
      clearTimeout(timer);
    };
  }, [duration, onClose]);

  const handleClose = () => {
    setIsVisible(false);
    onClose && onClose();
  };

  return isVisible ? (
    <SnackbarWrapper type={type} isMobile={isMobile} isVisible={isVisible}>
      <CloseButton onClick={handleClose}>&times;</CloseButton>
      {message}
    </SnackbarWrapper>
  ) : null;
};

export default Snackbar;
